import { createContext, useState } from 'react';

interface AdminContext {
  onboarding: {
    showSteps: boolean;
    setShowSteps: (value: boolean) => void;
  };
}

const ADMIN_CONTEXT: AdminContext = {
  onboarding: {
    showSteps: false,
    setShowSteps: (value) => value,
  },
};

export const AdminContext = createContext<AdminContext>(ADMIN_CONTEXT);

// This context is used for testing/overriding specific app-states
// set/override such app-states at admin page
const AdminProvider = ({ children }: { children: React.ReactNode }) => {
  const [showSteps, setShowSteps] = useState(false);

  return (
    <AdminContext.Provider value={{ onboarding: { showSteps, setShowSteps } }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
