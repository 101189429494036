import moment from 'moment';
import { useState } from 'react';
import { filter, flatten, uniq } from 'lodash';
import { IBookingEvent } from 'src/types';
import { getTimeOfDay } from 'src/utils/date-utils';
import { useGetTopics } from 'src/apis/topics-api';
import useSearchParams from 'src/hooks/use-search-params';
import useTopicFilter from 'src/components/shared/filters/use-topic-filter';
import useResetFilters from 'src/hooks/use-reset-filters';
import useTimeFilter from './use-time-filter';
import useEventTypeFilter from './use-event-type-filter';
import useLanguageFilter from './use-language-filter';
import useCategoriesFilter from './use-categories-filter';

interface UseOfferingFiltersProps {
  upcomingEvents: IBookingEvent[];
}

export type OfferingFilters =
  | 'type'
  | 'topics'
  | 'language'
  | 'time'
  | 'categories'
  | 'search';

export const OFFERING_FILTERS: OfferingFilters[] = [
  'type',
  'language',
  'time',
  'topics',
  'categories',
  'search',
];

const useOfferingFilters = ({ upcomingEvents }: UseOfferingFiltersProps) => {
  const { data: topics } = useGetTopics();
  const { resetFilters } = useResetFilters(OFFERING_FILTERS);

  const { searchParams } = useSearchParams();
  const [filtersLoading, setFiltersLoading] = useState(false);
  const MINIMUM_RATING = 4.5;

  const handleLoading = () => {
    setFiltersLoading(true);

    setTimeout(() => {
      setFiltersLoading(false);
    }, 500);
  };

  const timeFilter = useTimeFilter(upcomingEvents);
  const typeFilter = useEventTypeFilter(upcomingEvents);
  const languageFilter = useLanguageFilter(upcomingEvents);
  const categoriesFilter = useCategoriesFilter();

  const topicsFromEvents = uniq(
    flatten(upcomingEvents.map((item: any) => item?.topics)),
  );

  const availableTopics = filter(topics, (item) =>
    topicsFromEvents.includes(item.key),
  );

  const { handleChangeTopic, loading, filterByTopic } = useTopicFilter();

  const handleResetFilters = () => {
    resetFilters();

    setTimeout(() => {
      setFiltersLoading(false);
    }, 500);
  };

  const typeFromUrl = searchParams.get('type')?.split(',') ?? [];
  const languageFromUrl = searchParams.get('language')?.split(',') ?? [];
  const timeFromUrl = searchParams.get('time');
  const categoriesFromUrl = searchParams.get('categories')?.split(',') ?? [];
  const filteredByDropdown = upcomingEvents
    .filter((event) =>
      typeFromUrl.some(Boolean) ? typeFromUrl.includes(event.type) : true,
    )
    .filter((event) =>
      languageFromUrl.some(Boolean)
        ? languageFromUrl.includes(event.language)
        : true,
    )
    .filter((event) =>
      timeFromUrl
        ? event.sessions.every(
            (session) => getTimeOfDay(moment(session.startsAt)) === timeFromUrl,
          )
        : true,
    )
    .filter((event) =>
      categoriesFromUrl.some(Boolean)
        ? categoriesFromUrl.some((category) => {
            const categories = [
              { key: 'recommended', condition: event?.isRecommended },
              { key: 'starters', condition: event?.isBeginnerFriendly },
              { key: 'recurring', condition: event?.isRecurring },
              {
                key: 'top-rated',
                condition: event?.offeringSubject?.rating >= MINIMUM_RATING,
              },
            ];
            return categories.some(({ key, condition }) => {
              if (condition) return category === key;
              return false;
            });
          })
        : true,
    );

  const filteredUpcomingEvents = filterByTopic(filteredByDropdown, 'topics');

  return {
    typeFilter,
    timeFilter,
    languageFilter,
    categoriesFilter,
    handleChangeTopic,
    handleResetFilters,
    loading: filtersLoading || loading,
    availableTopics,
    filteredUpcomingEvents,
    handleLoading,
  };
};

export default useOfferingFilters;
