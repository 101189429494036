import { useLocation } from 'react-router-dom';
import { useGetUser } from 'src/apis/users-api';
import moment from 'moment';
import { useGetBookingHistory } from 'src/apis/bookings-api';
import { AdminContext } from 'src/app/admin-provider';
import { useContext } from 'react';
import useLocalStorage from './use-local-storage';

const RECOMMENDATION_EMPTY_BOX = 'RECOMMENDATION_EMPTY_BOX';

export const useAppConfiguration = () => {
  const { data: user } = useGetUser();
  const location = useLocation();

  const { data: bookingHistory } = useGetBookingHistory();
  const hasAccessToLiveSessions =
    !user?.subscription?.plan?.length ||
    user?.subscription?.plan?.tiers
      ?.filter((tier) =>
        ['INDIVIDUAL_SESSION', 'COMMUNITY_SESSION'].includes(tier.type),
      )
      ?.some((tier) => ['Unlimited', 'Limited'].includes(tier.option));

  const hasOnboarding =
    !!user?.lastAssessment?.SPARK_ONBOARDING ||
    !!user?.lastAssessment?.WEBAPP_ONBOARDING;

  const withUnlimitedSessions = user?.subscription?.type === 'UNLIMITED';
  const withSideNavigation = ![
    'assessment',
    'company-admin',
    'onboarding',
  ].some((key) => location.pathname.includes(key));
  const withCredits = !location.pathname.includes('assessment');
  const withNavbarLogo = !location.pathname.includes('assessment');
  const withLogoutBtn = !['assessment', 'onboarding'].some((key) =>
    location.pathname.includes(key),
  );
  const [
    dismissedRecommendations,
    setDismissedRecommentation,
  ] = useLocalStorage<string>(RECOMMENDATION_EMPTY_BOX, '');

  const showDismissRecommendations =
    hasOnboarding && bookingHistory?.length === 0 && !dismissedRecommendations;

  const showBookingWidget = false;
  const adminContext = useContext(AdminContext);

  const showOnboardingSteps =
    (!bookingHistory?.length ||
      bookingHistory?.every((booking) =>
        moment().isBefore(moment(booking.startsAt)),
      ) ||
      adminContext.onboarding.showSteps) &&
    hasAccessToLiveSessions;

  return {
    withSideNavigation,
    withCredits,
    withNavbarLogo,
    withLogoutBtn,
    withUnlimitedSessions,

    showDismissRecommendations,
    setDismissedRecommentation,
    showBookingWidget,
    showOnboardingSteps,
  };
};
