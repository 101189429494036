import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '../components/shared/notification/notification';

type ShowAlertParams = {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
};

type ContextProps = {
  showAlert: (params: ShowAlertParams) => void;
};

export const AlertContext = React.createContext<Partial<ContextProps>>({});

const AlertProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const [notification, setNotification] = useState({
    isOpen: false,
    severity: undefined,
    message: '',
  });

  const showAlert = useCallback(({ severity, message }) => {
    setNotification({ isOpen: true, severity, message });
  }, []);

  const hideAlert = useCallback(() => {
    setNotification((state) => ({ ...state, isOpen: false }));
  }, []);

  return (
    // @ts-ignore next
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      <Snackbar
        open={notification.isOpen}
        autoHideDuration={8000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={hideAlert}
      >
        <Box>
          {/* @ts-ignore next */}
          <Alert
            variant="outlined"
            severity={notification.severity || 'error'}
            onClose={hideAlert}
          >
            {notification.message}
          </Alert>
        </Box>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
