import { makeStyles } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ITheme } from 'src/style/theme';
import CloseIcon from './assets/close.svg?react';

const useStyles = makeStyles()((theme: ITheme) => ({
  contentRoot: {
    paddingTop: 0,
    margin: 0,
  },
  contact: {
    marginTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

interface ModalExportDataProps {
  onClose: () => void;
  modal: { isOpen: boolean };
}

const ModalExportData = (props: ModalExportDataProps) => {
  const { onClose, modal } = props;

  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Dialog open={modal.isOpen} onClose={onClose}>
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{t('ModalExportData.dialogTitle')}</DialogTitle>
      <DialogContent
        classes={{
          root: classes.contentRoot,
        }}
      >
        <DialogContentText>{t('ModalExportData.dialogText')}</DialogContentText>
        <DialogContentText
          classes={{
            root: classes.contact,
          }}
        >
          <a href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}>
            {import.meta.env.VITE_CONTACT_EMAIL}
          </a>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ModalExportData;
