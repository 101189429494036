import BookingsIcon from 'src/assets/icons/bookings.svg?react';
import ProfileIcon from 'src/assets/icons/profile.svg?react';
import DashboardIcon from 'src/assets/icons/dashboard.svg?react';
import InfoIcon from 'src/assets/icons/Info.svg?react';
import LeadershipIcon from 'src/components/shared/filters/assets/recommendations.svg?react';
import React from 'react';

interface NavigationItem {
  title: string;
  path: string | string[];
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  hasNewContent?: boolean;
}

const navigationItems: NavigationItem[] = [
  {
    title: 'Menu.home',
    path: [
      '/your-recommendation',
      '/group-sessions',
      '/library',
      '/individual-sessions',
    ],
    icon: DashboardIcon,
  },
  {
    title: 'v2.menu.leadership',
    path: [
      '/leadership/group-sessions',
      '/leadership/individual-sessions',
      '/leadership/library',
    ],
    icon: LeadershipIcon,
  },
  {
    title: 'Menu.bookings',
    path: '/bookings',
    icon: BookingsIcon,
    hasNewContent: !!localStorage.getItem('HAS_NEW_BOOKING'),
  },
  {
    title: 'Menu.account',
    path: '/account',
    icon: ProfileIcon,
  },
  {
    title: 'Admin',
    path: '/admin',
    icon: InfoIcon,
  },
];

export default navigationItems;
