import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Input from '../input/input';

interface FormInputProps {
  id?: string;
  type?: 'email' | 'hidden' | 'password' | 'text';
  name: string;
  required?: boolean;
  minLength?: number;
  validate?: boolean;
  style?: React.CSSProperties;
  className?: string;
  info?: string;
  boxProps?: BoxProps;
}

const FormInput = (props: FormInputProps) => {
  const {
    id,
    type = 'text',
    name,
    required,
    minLength,
    validate,
    info,
    boxProps,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { errors, register } = useFormContext();
  const generateValidators = useCallback(() => {
    const validators = {
      required: '',
      pattern: {},
      minLength: 0,
      validate: true,
    };
    if (required && info === undefined) {
      validators.required = t('v2.global.label_helperText_mandatoryField');
    } else if (required && info) {
      validators.required = info;
    }
    if (type === 'email') {
      validators.pattern = {
        // eslint-disable-next-line unicorn/better-regex
        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        message: t('FormInput.email'),
      };
    }
    if (minLength) {
      validators.minLength = minLength;
    }
    if (validate) {
      validators.validate = validate;
    }
    return validators;
  }, [required, type, minLength, validate, t]);

  return (
    <Box {...boxProps}>
      <Input
        // @ts-ignore next
        id={id}
        type={type}
        name={name}
        error={errors[name]}
        // @ts-ignore next
        ref={register(generateValidators())}
        required={required}
        {...rest}
      />
      {info && !errors[name] && (
        <Typography pt="8px" variant="caption">
          {info}
        </Typography>
      )}
    </Box>
  );
};

export default FormInput;
