import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShieldSVG from 'src/assets/icons/shield.svg?react';
import ShareSVG from 'src/assets/icons/share.svg?react';
import { useContext } from 'react';
import { AlertContext } from 'src/app/alert-provider';
import { analyticEvents } from 'src/utils/analytic-events';
import { useStyles } from './styles';
import usePrivacyInfo from '../privacy-info-drawer/use-privacy-info';
import Tooltip from '../shared/tooltip/tooltip';

interface PrivacyCtaProps {
  onClick?: () => void;
}

const PrivacyCta = ({ onClick }: PrivacyCtaProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (typeof onClick !== 'function') return null;

  return (
    <Typography
      className={classes.privacyLink}
      onClick={onClick}
      variant="buttonDefault"
    >
      <ShieldSVG />
      {t('v2.global.button_privacy')}
    </Typography>
  );
};
interface OfferingSideFooterProps {
  onConfirm: () => void;
  ctaSecondary?: string | boolean;
  onCtaSecondary?: () => void;
  ctaDisabled?: boolean;
  disabledTooltip?: string;
}

const OfferingDrawerFooter = ({
  onConfirm,
  ctaSecondary,
  onCtaSecondary,
  ctaDisabled,
  disabledTooltip,
}: OfferingSideFooterProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const privacyInfo = usePrivacyInfo();
  const { showAlert } = useContext(AlertContext);

  const onShowPrivacyDrawer = () => {
    privacyInfo.onOpen({
      state: { previous: location.search },
      paramsRemoved: ['event-id', 'name'],
    });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    analyticEvents.linkShared({ type: 'Group Session Drawer' });
    showAlert({
      severity: 'success',
      message: t('v2.global.snackbar_label_linkCopiedSuccessfully'),
    });
  };

  if (ctaSecondary) {
    return (
      <>
        <PrivacyCta onClick={onShowPrivacyDrawer} />
        <Typography
          onClick={onCtaSecondary}
          className={classes.secondaryCta}
          variant="buttonDefault"
        >
          {ctaSecondary}
        </Typography>
      </>
    );
  }

  return (
    <>
      <PrivacyCta onClick={onShowPrivacyDrawer} />
      <Box className={classes.buttons}>
        <Tooltip title={t('v2.global.button_shareEvent')} variant="buttonSmall">
          <Button
            className={classes.share}
            variant="outlined"
            onClick={handleCopyToClipboard}
          >
            <ShareSVG />
          </Button>
        </Tooltip>
        <Tooltip title={disabledTooltip} variant="buttonSmall">
          <Box width="100%">
            <Button
              component="button"
              variant="contained"
              color="primary"
              className={classes.footerButton}
              onClick={onConfirm}
              disabled={ctaDisabled}
            >
              {t('v2.global.button_label_bookEventNow')}
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};

export default OfferingDrawerFooter;
